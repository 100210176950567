import { useEffect } from "react";
const TextAnim = () => {
  useEffect(() => {
    console.log("anim callback runs");
    const headings = document.querySelectorAll(".main-heading");

    const headingsAnimFadeOut = function (heading) {
      return new Promise((resolve) => {
        setTimeout(() => {
          heading.style.opacity = "0";
          resolve();
        }, 100);
      });
    };
    const headingsAnimFadeIn = function (heading) {
      return new Promise((resolve) => {
        setTimeout(() => {
          heading.style.opacity = "1";
          resolve();
        }, 100);
      });
    };
    const wait = function (seconds) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(), seconds * 1000);
      });
    };

    const fadeInHeadings = async function () {
      await headingsAnimFadeIn(headings[0]);

      await wait(5);
      await headingsAnimFadeOut(headings[0]);

      await headingsAnimFadeIn(headings[1]);

      await wait(2.5);
      await headingsAnimFadeOut(headings[1]);

      await headingsAnimFadeIn(headings[2]);

      await wait(2.5);
      await headingsAnimFadeOut(headings[2]);
      fadeInHeadings();
    };

    fadeInHeadings();
  }, []);

  return <span></span>;
};
export default TextAnim;
