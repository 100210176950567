const Plastic = (props) => {
  return (
    <section className="section-surgery flex container">
      <div className="surgery-heading">{props.langFile.title}</div>
      <div className="surgery-container container flex">
        <p>{props.langFile.text}</p>
      </div>
      <div className="surgery-desc container flex">
        <div className="surgery-types-container flex">
          <div>{props.langFile.hair}</div>
          <div>{props.langFile.breast}</div>
          <div>{props.langFile.sexual}</div>
          <div>{props.langFile.rhinoplasty}</div>
        </div>
        <div>{props.langFile.motto}</div>
        <div className="call-us">
          {props.langFile.reach} <br></br>
          <b>+90 533 358 93 13</b>
        </div>
      </div>
    </section>
  );
};

export default Plastic;
