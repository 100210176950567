import { Link } from "react-router-dom";
const Services = (props) => {
  console.log(props);
  return (
    <section className="section-services flex">
      <div className="section-services-heading flex">We Provide</div>
      <div className="services-container flex container ">
        <div>
          <Link to="" className="services-link">
            {props.langFile.skincare}
          </Link>
        </div>
        <div>
          <Link to="" className="services-link">
            {props.langFile.personalcare}
          </Link>
        </div>
        <div>
          <Link to="surgery" className="services-link">
            {props.langFile.surgery}
          </Link>
        </div>
      </div>
      <div className="to-navigate">Click to navigate</div>
    </section>
  );
};

export default Services;
