import Modal from "./UI/Modal";
import classes from "./SetLanguage.module.css";
import { useDispatch } from "react-redux";
import { languageActions } from "../store";
import { Fragment, useEffect, useState } from "react";
const SetLanguage = (props) => {
  let data;
  const [country, setCountry] = useState();
  const [isChangeLang, setIsChangeLang] = useState(false);
  // const currentLanguage = useSelector((state) => state.language.language);
  const dispatch = useDispatch();

  const onClickHandler = (e) => {
    props.onCloseLanguage();
    dispatch(languageActions.setLanguage(e.target.id));
    localStorage.setItem("country", e.target.id);
  };

  const onChangeLangHandler = () => {
    setIsChangeLang(true);
  };

  const storedCountry = localStorage.getItem("country");

  useEffect(() => {
    const location = async () => {
      const response = await fetch("https://ipapi.co/json/");
      data = await response.json();
      setCountry(data.country_name);
      if (storedCountry) {
        dispatch(languageActions.setLanguage(storedCountry));
        return;
      }
      console.log(data);
      switch (data.languages.slice(0, 2)) {
        case "en":
          dispatch(languageActions.setLanguage("en"));
          localStorage.setItem("country", "en");
          break;
        case "tr":
          dispatch(languageActions.setLanguage("tr"));
          localStorage.setItem("country", "tr");
          break;
        default:
          dispatch(languageActions.setLanguage("en"));
          localStorage.setItem("country", "en");
      }
      console.log();
    };
    location();
  }, []);

  const buttonContent = isChangeLang ? (
    <Fragment>
      <button id="tr" className={classes.button} onClick={onClickHandler}>
        TR
      </button>
      <button id="en" className={classes.button} onClick={onClickHandler}>
        EN
      </button>
      <button id="ae" className={classes.button} onClick={onClickHandler}>
        AE
      </button>
      <button className={classes.button} onClick={onClickHandler}>
        FR
      </button>
    </Fragment>
  ) : (
    <button className={classes.button} onClick={onChangeLangHandler}>
      {props.langFile.change}
    </button>
  );

  return (
    <Modal onClose={props.onCloseLanguage}>
      <div className={classes.container}>
        <div>
          {props.langFile.welcome}
          <br></br>
          <span className={classes.hayberya}>{props.langFile.hayberya}</span>
        </div>
        <div>
          <p className={classes["country-info"]}>
            {props.langFile.lookslike} <br></br>
            <span className={classes.country}>{country}</span>
          </p>
          <div className={classes["button-container"]}>{buttonContent}</div>
        </div>
      </div>
    </Modal>
  );
};
export default SetLanguage;
