import React from "react";
import Collage from "../components/Collage";
import Policy from "../components/Policy";
import Featured from "../components/Featured";
import HomeAnim from "../components/UI/HomeAnim";
import Services from "../components/Services";
import { useState, useEffect, useRef } from "react";
import logotr from "../assets/img/logotr.png";
import globeOutline from "../assets/img/globe-outline.svg";
import { Link, NavLink } from "react-router-dom";

const HomePage = (props) => {
  console.log("Home runs");
  const [isNavVisible, setIsNavVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsNavVisible(true);
        } else {
          setIsNavVisible(false);
        }
      },
      { root: null, threshold: 0.2 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const featuredDatas = [
    {
      id: "featured-1",
      title: props.langFile.featured.featured1.title,
      text: props.langFile.featured.featured1.text,
      leftAlign: false,
    },
    {
      id: "featured-2",
      title: props.langFile.featured.featured2.title,
      text: props.langFile.featured.featured2.text,
      leftAlign: true,
    },
    {
      id: "featured-3",
      title: props.langFile.featured.featured3.title,
      text: props.langFile.featured.featured3.text,
      leftAlign: false,
    },
  ];

  return (
    <>
      <main>
        <Collage />
        <Services langFile={props.langFile.services} />
        <section ref={sectionRef}>
          {isNavVisible && (
            <div className="sticky-nav">
              <nav className="navigation flex">
                <div className="nav-container flex container">
                  <div className="logo-container">
                    <img className="logo" src={logotr} />
                  </div>
                  <div>
                    <a
                      className="nav-link globe-container flex"
                      href="#"
                      onClick={props.onShowLanguage}
                    >
                      <img className="globe" src={globeOutline} />{" "}
                      {props.langFile.hero.region}
                    </a>
                  </div>
                </div>
              </nav>
              <nav className="nav-second flex container">
                <div className="nav-second-container flex container">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active nav-link" : "nav-link"
                    }
                    to=""
                    end
                  >
                    {props.langFile.hero.home}
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active nav-link" : "nav-link"
                    }
                    to="careers"
                    end
                  >
                    {props.langFile.hero.careers}
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active nav-link" : "nav-link"
                    }
                    to="surgery"
                    end
                  >
                    {props.langFile.hero.surgery}
                  </NavLink>

                  <a href="#" className="nav-link">
                    {props.langFile.hero.personalcare[0]}&nbsp;
                    {props.langFile.hero.personalcare[1]}
                  </a>
                  <a href="#" className="nav-link">
                    {props.langFile.hero.skincare[0]}&nbsp;
                    {props.langFile.hero.skincare[1]}
                  </a>
                </div>
              </nav>
            </div>
          )}
          {featuredDatas.map((el) => (
            <Featured key={el.id} data={el} />
          ))}
          <Policy langFile={props.langFile} />
        </section>
      </main>
      <HomeAnim />
    </>
  );
};
export default React.memo(HomePage);
