import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/Home";
import Carrers from "./pages/Carrers";
import RootLayout from "./pages/Root";
import ErrorPage from "./pages/Error";
import { useSelector } from "react-redux";
import en from "./lang/en.json";
import tr from "./lang/tr.json";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { languageActions } from "./store";
import Surgery from "./pages/Surgery";

const App = function App() {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.language.language);
  const [cartIsShown, setCartIsShown] = useState(true);

  useEffect(() => {
    const storedCountry = localStorage.getItem("country");
    if (storedCountry) {
      setCartIsShown(false);
    } else {
      dispatch(languageActions.setLanguage(storedCountry));
    }
  }, [dispatch]); /******** !!!!!!!!!!!!!!!!!! BUNU EKLEDİN KODU BOZABİLİR */

  let langFile;
  switch (currentLanguage) {
    case "en":
      langFile = en;
      break;
    case "tr":
      langFile = tr;
      break;
    default:
      langFile = en;
  }

  const showSetLanguageHandler = () => {
    setCartIsShown(true);
  };
  const hideSetLanguageHandler = () => {
    setCartIsShown(false);
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <RootLayout
          hideSetLanguageHandler={hideSetLanguageHandler}
          showSetLanguageHandler={showSetLanguageHandler}
          langFile={langFile}
          cartIsShown={cartIsShown}
        />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <HomePage langFile={langFile} />,
        },
        {
          path: "careers",
          element: <Carrers langFile={langFile.carrers} />,
        },
        {
          path: "surgery",
          element: <Surgery langFile={langFile.surgery} />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
