const Featured = (props) => {
  const leftAlign = props.data.leftAlign;
  const whatContainer = props.data.id.slice(-1);
  return (
    <section className="section-featured" id={props.data.id}>
      <div className={`featured-container featured-container-${whatContainer}`}>
        <div className="featured-description">
          <div className={`featured-cover ${leftAlign ? "fc2" : ""}`}>
            <p className={`featured-heading hiddden ${leftAlign ? "fh2" : ""}`}>
              {props.data.title}
            </p>

            <p
              className={`featured-description-text hiddden ${
                leftAlign ? "fdt2" : ""
              }`}
            >
              {props.data.text}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Featured;
