import logotr from "../assets/img/logotr.png";
const Footer = (props) => {
  return (
    <>
      <section id="policy" className="section-policy flex container">
        <div className="footer-container flex container">
          <img className="footer-logo" src={logotr} />
        </div>
      </section>
      <div className="footer-cr flex container">
        {props.langFile}
        <br></br>Nispetiye Mah. Gazi Güçnar Sk. No: 4 Kapı No: 2, Istanbul,
        Besiktas TR
      </div>
    </>
  );
};

export default Footer;
