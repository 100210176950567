const Collage = () => {
  return (
    <section className="section-log">
      <div className="img-container-container">
        <div className="img-container">
          <a href="#featured-3">
            <img
              src="https://hayberyamain.s3.eu-central-1.amazonaws.com/img/hayberya1.jpg"
              className="log-img"
            />
          </a>
        </div>
        <div className="img-container">
          <a href="#featured-2">
            <img
              src="https://hayberyamain.s3.eu-central-1.amazonaws.com/img/hayberya3.png"
              className="log-img"
            />
          </a>
        </div>
        <div className="img-container">
          <img
            src="https://hayberyamain.s3.eu-central-1.amazonaws.com/img/hayberyaclic.jpg"
            className="log-img"
          />
        </div>
        <div className="img-container">
          <img
            src="https://hayberyamain.s3.eu-central-1.amazonaws.com/img/hayberya2.jpeg"
            className="log-img"
          />
        </div>
        <div className="img-container">
          <a href="#featured-1">
            <img
              src="https://hayberyamain.s3.eu-central-1.amazonaws.com/img/hayberya55.png"
              className="log-img"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Collage;
