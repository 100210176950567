import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState: { language: "en" },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

const store = configureStore({
  reducer: { language: languageSlice.reducer },
});

export const languageActions = languageSlice.actions;
export default store;
