import { useEffect } from "react";

const HomeAnim = () => {
  useEffect(() => {
    const hiddenElements = [
      ...document.querySelectorAll(".hidden"),
      ...document.querySelectorAll(".hidden-slow"),
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    hiddenElements.forEach((e1) => observer.observe(e1));

    let didScroll = false;
    let featuredContainerScroll = document.querySelectorAll(
      ".featured-container"
    );
    const sectionFeatured = document.querySelector(".section-featured");
    // const sectionWhatWeDo = document.querySelector(".what-we-do");

    const scrollFunction = function (entries) {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const scrollInProgress = () => {
          didScroll = true;
        };

        const raf = () => {
          if (didScroll) {
            featuredContainerScroll.forEach((el) => {
              el.style.opacity = `${
                1 - el.getBoundingClientRect().top / window.innerHeight
              }`;
            });

            didScroll = false;
          }
          requestAnimationFrame(raf);
        };

        requestAnimationFrame(raf);
        window.addEventListener("scroll", scrollInProgress);
      }
    };

    const scrollObserver = new IntersectionObserver(scrollFunction, {
      root: null,
      threshold: 0,
    });
    featuredContainerScroll.forEach((el) => scrollObserver.observe(el));
  }, []);
  return <span></span>;
};
export default HomeAnim;
