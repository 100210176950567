const Policy = (props) => {
  return (
    <section className="section-policy flex container">
      <p className="parallax">{props.langFile.policy.about}</p>
      <div className="policy-container flex container hidden-slow">
        <div className="policy-heading-container container flex">
          <p className="policy-heading">{props.langFile.policy.policy}</p>
        </div>
        <div className="policy-text-container flex">
          <p className="policy-text">{props.langFile.policy["policy-text"]}</p>
        </div>
      </div>
      <div className="policy-container flex container hidden-slow">
        <div className="policy-heading-container container flex">
          <p className="policy-heading">{props.langFile.policy.corporation}</p>
        </div>
        <div className="policy-text-container flex">
          <p className="policy-text">
            {props.langFile.policy["corporation-text"]}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Policy;
