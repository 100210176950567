import logotr from "../assets/img/logotr.png";
import globeOutline from "../assets/img/globe-outline.svg";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { Fragment } from "react";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import TextAnim from "./UI/Anim.js";
const Hero = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <Fragment>
      {isLoading && <LoadingSpinner />}
      <section id="section-hero">
        <div>
          <nav className="navigation flex">
            <div className="nav-container flex container">
              <div className="logo-container">
                <img className="logo" src={logotr} />
              </div>
              <div>
                <a
                  className="nav-link globe-container flex"
                  href="#"
                  onClick={props.onShowLanguage}
                >
                  <img className="globe" src={globeOutline} />{" "}
                  {props.langFile.region}
                </a>
              </div>
            </div>
          </nav>
          <nav className="nav-second flex container">
            <div className="nav-second-container flex container">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
                to=""
                end
              >
                {props.langFile.home}
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
                to="careers"
                end
              >
                {props.langFile.careers}
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
                to="surgery"
                end
              >
                {props.langFile.surgery}
              </NavLink>

              <a
                href="http://zabun.com.tr"
                target="_blank"
                className="nav-link"
              >
                {props.langFile.personalcare[0]}&nbsp;
                {props.langFile.personalcare[1]}
              </a>
              <a href="http://hyberia.co" target="_blank" className="nav-link">
                {props.langFile.skincare[0]}&nbsp;
                {props.langFile.skincare[1]}
              </a>
            </div>
          </nav>
        </div>
        <div className="header-container flex container">
          <div className="heading-container">
            <p className="main-heading en">{props.langFile.firstheading}</p>
            <p className="main-heading ge">
              Bessere Pflege für eine vernetzte Welt.
            </p>
            <p className="main-heading fr">
              Améliorer les soins pour un monde connecté.
            </p>
          </div>
          <a href="#policy">
            <button className="button">{props.langFile.policy}</button>
          </a>
        </div>
      </section>
      <TextAnim />
    </Fragment>
  );
};

export default Hero;
