import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Hero from "../components/Hero";
import SetLanguage from "../components/SetLanguage";
import Footer from "../components/Footer";

function RootLayout(props) {
  return (
    <Fragment>
      {props.cartIsShown && (
        <SetLanguage
          langFile={props.langFile.modal}
          onCloseLanguage={props.hideSetLanguageHandler}
        />
      )}
      <header>
        <Hero
          onShowLanguage={props.showSetLanguageHandler}
          langFile={props.langFile.hero}
        />
      </header>
      <Outlet />
      <Footer langFile={props.langFile.footer} />
    </Fragment>
  );
}
export default RootLayout;
