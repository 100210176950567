import logotr from "../assets/img/logotr.png";
const ErrorPage = () => {
  return (
    <div className="error-container flex container">
      <div className="error-content-container flex container">
        <div className="logo-container">
          <img className="logo-error" src={logotr} />
        </div>
        <h1>Oops!..</h1>
        <p>Could not find this page!</p>
        <a
          href="/"
          className="nav-link"
          style={{ textDecoration: "underline" }}
        >
          Navigate back to home
        </a>
      </div>
    </div>
  );
};

export default ErrorPage;
