const Career = (props) => {
  return (
    <section className="section-careers flex container">
      <div className="careers-heading">{props.langFile.title}</div>
      <div className="current-careers-container container flex">
        <p style={{ fontSize: "2.7vw" }}>{props.langFile.text}</p>
      </div>
    </section>
  );
};
export default Career;
